<!-- 模拟选科 -->
<template>
  <div class="main-xuanke" style="padding-top: 40px">
    <!-- 进度条 可以回退到前面的步骤，但不可以快进 -->
    <div class="steps">
      <el-steps :active="currentStep" simple>
        <el-step title="首选科目（二选一）" icon="el-icon-tickets"></el-step>
        <el-step title="再选科目（四选二）" icon="el-icon-document-copy"></el-step>
        <el-step title="生成方案" icon="el-icon-finished"></el-step>
      </el-steps>
    </div>

    <div class="table-item">
      <!-- 填写信息 -->
      <div v-if="currentStep == 0" class="form-item">
        <el-form :model="postForm" :rules="rules" ref="postForm" label-width="140px" class="demo-postForm">
          <el-form-item label="账号" prop="account">
            <el-input v-model="account" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="学校" prop="schoolname">
            <el-select v-model="postForm.schoolname" placeholder="请选择学校">
              <el-option label="成都市第37中" value="成都市第37中"></el-option>
              <el-option label="学校2" value="2021"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="高中入学年份" prop="enrollyear">
            <el-select v-model="postForm.enrollyear" placeholder="请选择入学年份">
              <el-option label="2022" value="2022"></el-option>
              <el-option label="2021" value="2021"></el-option>
              <el-option label="2020" value="2020"></el-option>
              <el-option label="2019" value="2019"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="姓名" prop="username">
            <el-input v-model="postForm.username" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="学号" prop="studentidnum">
            <el-input v-model="postForm.studentidnum" placeholder="请输入学号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('postForm')">确认，下一步</el-button>
            <el-button @click="resetForm('postForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- 填写信息end -->

      <!-- 二选一 -->
      <div v-else-if="currentStep == 1" class="course-choose">
        <div class="recommand-subject-container">
          <div class="recommand-title" v-if="subjectRecommandGet">
            根据您的学科表现与其他相关综合素质，<span class="strong-span">&nbsp;推荐&nbsp;</span>
            您的选科组合是：
            <div class="recommand-sub" v-for="(item, index) in recommandSubjects" :key="index">
              <span>{{ index + 1 }}.</span>
              <span class="recommand-subject">&nbsp;{{ item }}&nbsp;&nbsp;</span>
              <span>推荐度&nbsp;</span>
              <span>{{ recommandScore[index] }}</span>
            </div>
          </div>
          <div class="main-parts-wrapper" v-else>{{ msg }}暂无选科推荐</div>
        </div>

        <div class="label1">首选科目(二选一)</div>
        <div class="choose-tag">
          <el-tag class="condition-tag" v-for="(item, index) in choose1from2" :key="item.name"
            :effect="item.choosed ? 'dark' : 'plain'" @click="selectCourseLabel(index, '2')">
            {{ item.name }}
          </el-tag>
        </div>
        <div>
          <el-button type="primary" @click="confirmCourse(2)">确认，下一步</el-button>
        </div>
      </div>
      <!-- 二选一end -->

      <!-- 四选二 -->
      <div v-else-if="currentStep == 2" class="course-choose">
        <div class="recommand-subject-container">
          <div class="recommand-title" v-if="subjectRecommandGet">
            根据您的学科表现与其他相关综合素质，<span class="strong-span">&nbsp;推荐&nbsp;</span>
            您的选科组合是：
            <div class="recommand-sub" v-for="(item, index) in recommandSubjects" :key="index">
              <span>{{ index + 1 }}.</span>
              <span class="recommand-subject">&nbsp;{{ item }}&nbsp;&nbsp;</span>
              <span>推荐度&nbsp;</span>
              <span>{{ recommandScore[index] }}</span>
            </div>
          </div>
          <div class="main-parts-wrapper" v-else>{{ msg }}暂无选科推荐</div>
        </div>

        <div class="label1">再选科目(四选二)</div>
        <div class="choose-tag">
          <el-tag class="condition-tag" v-for="(item, index) in choose2from4" :key="item.name"
            :effect="item.choosed && item.selectable ? 'dark' : 'plain'" disabled="true"
            :class="item.selectable !== true ? 'unable-choose' : ''" @click="selectCourseLabel(index, '4')">
            {{ item.name }}
          </el-tag>
        </div>
        <div>
          <el-button class="back-btn" type="text" style="margin-top: 10px" @click="backToPreStep()" size="medium">返回上一步
          </el-button>
          <el-button type="primary" @click="confirmCourse(4)"
            style="margin-left: 0; margin-top: 10px">确认，下一步</el-button>
        </div>
      </div>
      <!-- 四选二end -->

      <!-- 结束 -->
      <div v-else-if="currentStep == 3" class="major-choose">
        <div v-if="getReport == false">
          <div class="label1">
            您的选科结果:
            <span class="result-span" v-if="postForm.choose1from2 == 'physics_require'">物理</span>
            <span class="result-span" v-else>历史</span>

            <span class="result-span" v-if="postForm.choose2from4[0] == 'chemistry_require'">化学</span>
            <span class="result-span" v-else-if="postForm.choose2from4[0] == 'biology_require'">生物</span>
            <span class="result-span" v-else-if="postForm.choose2from4[0] == 'politics_require'">政治</span>
            <span class="result-span" v-else-if="postForm.choose2from4[0] == 'geography_require'">地理</span>

            <span class="result-span" v-if="postForm.choose2from4[1] == 'chemistry_require'">化学</span>
            <span class="result-span" v-else-if="postForm.choose2from4[1] == 'biology_require'">生物</span>
            <span class="result-span" v-else-if="postForm.choose2from4[1] == 'politics_require'">政治</span>
            <span class="result-span" v-else-if="postForm.choose2from4[1] == 'geography_require'">地理</span>
          </div>
          <div class="major-page">
            <major-index-page :independentPage="false" :labchoose1from2="postForm.choose1from2"
              :labchoose2from4="postForm.choose2from4" @getXuankeReport="getXuankeReport">
            </major-index-page>
          </div>
        </div>
        <!-- 选科报告 -->
        <div class="select-res" v-else>
          <div class="title">选科规划报告</div>
          <div class="reselect-btn">
            <el-button class="search-btn" type="text" @click="reSelectSubject" size="medium">重新选择专业
            </el-button>
            <!-- <el-button class="search-btn" type="text" @click="reSelectCourse" size="medium">重新选择科目
                        </el-button> -->
          </div>
          <div class="res-body">
            <!-- report begin -->
            <div class="report-page">
              <table
                class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                <tbody>
                  <tr>
                    <td>测评人</td>
                    <td>
                      {{ postForm.username }}
                    </td>
                  </tr>
                  <tr>
                    <td>所在中学</td>
                    <td>
                      {{ postForm.schoolname }}
                    </td>
                  </tr>
                  <tr>
                    <td>年级</td>
                    <td>
                      {{ postForm.enrollyear }}
                    </td>
                  </tr>
                  <tr>
                    <td>班级</td>
                    <td>
                      {{ postForm.classname }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="report-page">
              <div class="page-title">一、首选科目</div>
              <div class="part-describe">
                您的首选科目是:<span class="result-span" v-if="postForm.choose1from2 == 'physics_require'">物理</span>
                <span class="result-span" v-else>历史</span>
              </div>
            </div>
            <div class="report-page">
              <div class="page-title">二、四选二科目</div>
              <div class="part-describe">
                您的四选二科目是:
                <span class="result-span" v-if="postForm.choose2from4[0] == 'chemistry_require'">化学</span>
                <span class="result-span" v-else-if="postForm.choose2from4[0] == 'biology_require'">生物</span>
                <span class="result-span" v-else-if="postForm.choose2from4[0] == 'politics_require'">政治</span>
                <span class="result-span" v-else-if="postForm.choose2from4[0] == 'geography_require'">地理</span>

                <span class="result-span" v-if="postForm.choose2from4[1] == 'chemistry_require'">化学</span>
                <span class="result-span" v-else-if="postForm.choose2from4[1] == 'biology_require'">生物</span>
                <span class="result-span" v-else-if="postForm.choose2from4[1] == 'politics_require'">政治</span>
                <span class="result-span" v-else-if="postForm.choose2from4[1] == 'geography_require'">地理</span>
              </div>
            </div>
            <div class="report-page">
              <div class="page-title">三、选科组合描述</div>
              <div class="page-sub-title">1. 选科组合学科特性分析</div>
              <div class="report-table-div">
                <table
                  class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                  <thead>
                    <tr>
                      <th class="lj-text-middle">学科组合</th>
                      <th class="lj-text-middle">学科特性</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ composeName }}
                      </td>
                      <td class="td-text-left">
                        {{ xuankeInfo.feature }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="page-sub-title">2. 选科组合适合人群分析</div>
              <div class="report-table-div">
                <table
                  class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                  <thead>
                    <tr>
                      <th class="lj-text-middle">学科组合</th>
                      <th class="lj-text-middle">适合人群</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ composeName }}
                      </td>
                      <td class="td-text-left">
                        <div v-for="(item, index) in xuankeInfo.suitablePerson" :key="index">
                          {{ `${index + 1}.${item}` }}
                        </div>
                        <!-- {{ xuankeInfo.suitablePerson }} -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="page-sub-title">3. 选科组合专业覆盖率分析</div>
              <div class="report-table-div">
                <table
                  class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                  <thead>
                    <tr>
                      <th class="lj-text-middle">学科组合</th>
                      <th class="lj-text-middle">可选专业覆盖率</th>
                      <th class="lj-text-middle">可选专业情况</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ composeName }}
                      </td>
                      <td class="td-text-left">
                        {{ xuankeInfo.coverRate }}
                      </td>
                      <td class="td-text-left">
                        {{ xuankeInfo.coverStatus }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="page-sub-title">4. 选科组合优势与劣势分析</div>
              <div class="report-table-div">
                <table
                  class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                  <thead>
                    <tr>
                      <th class="lj-text-middle">学科组合</th>
                      <th class="lj-text-middle">优势解析</th>
                      <th class="lj-text-middle">劣势解析</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ composeName }}
                      </td>
                      <td class="td-text-left">
                        <div v-for="(item, index) in xuankeInfo.advantage" :key="index">
                          {{ `${index + 1}.${item}` }}
                        </div>
                      </td>
                      <td class="td-text-left">
                        <div v-for="(item, index) in xuankeInfo.disadvantage" :key="index">
                          {{ `${index + 1}.${item}` }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="page-sub-title">5. 热门专业与限选专业分析</div>
              <div class="report-table-div">
                <table
                  class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                  <thead>
                    <tr>
                      <th class="lj-text-middle">学科组合</th>
                      <th class="lj-text-middle">热门专业选择与限选专业</th>
                      <th class="lj-text-middle">可选专业详情</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {{ composeName }}
                      </td>
                      <td class="td-text-left">
                        <div style="padding-bottom: 1rem">
                          热门专业推荐：{{ xuankeInfo.hotOrLimitMajor.hot }}
                        </div>
                        <div>
                          限选专业：{{ xuankeInfo.hotOrLimitMajor.limit }}
                        </div>
                      </td>
                      <td class="td-text-left"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="report-page">
              <div class="page-title">四、选科组合可选专业详情</div>
              <div class="part-describe">
                <span class="result-span">{{ composeName }}</span>组合可选专业统计（{{ xuankeInfo.majors.length }}个）
              </div>
              <div class="report-table-div">
                <table
                  class="lj-table lj-table-bordered lj-table-centered lj-table-radius lj-table-striped lj-table-hover">
                  <thead>
                    <tr>
                      <th class="lj-text-middle">学科门类</th>
                      <th class="lj-text-middle">专业类</th>
                      <th class="lj-text-middle">内设专业</th>
                      <th class="lj-text-middle">专业数量</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in xuankeInfo.majors" :key="index">
                      <td :rowspan="item.spanSize" v-if="item.firstSpan === true">
                        {{ item.bigClass }}
                      </td>
                      <td>
                        {{ item.majorName }}
                      </td>
                      <td class="td-text-left">
                        {{ item.subMajors }}
                      </td>
                      <td>
                        {{ item.nums }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="report-page">
              <div class="page-title">五、目标专业的选科要求</div>
              <div class="part-describe">您的专业选择方案如下:</div>
              <el-table :data="selectMajorList" stripe :header-cell-style="{
                color: '#ff0000',
                fontSize: '24px',
                fontWeight: 'blod'
              }">
                <el-table-column prop="logo" label="" width="80" :key="1">
                  <template slot-scope="scope">
                    <el-image style="width: 60px; height: 60px" :src="scope.row.logo" fit="fill">
                    </el-image>
                  </template>
                </el-table-column>
                <el-table-column prop="collegeName" label="学校" :key="2">
                </el-table-column>
                <el-table-column prop="majorName" label="专业" :key="3">
                </el-table-column>
                <el-table-column prop="courseRequire" label="选科要求" :key="6" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.courseRequire[1] == 1">化学(可选)
                    </span>
                    <span v-if="scope.row.courseRequire[1] == 2">化学(必选)
                    </span>
                    <span v-if="scope.row.courseRequire[2] == 1">生物(可选)
                    </span>
                    <span v-if="scope.row.courseRequire[2] == 2">生物(必选)
                    </span>
                    <span v-if="scope.row.courseRequire[3] == 1">政治(可选)
                    </span>
                    <span v-if="scope.row.courseRequire[3] == 2">政治(必选)
                    </span>
                    <span v-if="scope.row.courseRequire[5] == 1">地理(可选)
                    </span>
                    <span v-if="scope.row.courseRequire[5] == 2">地理(必选)
                    </span>
                    <span v-if="scope.row.courseRequire[0] == -1" style="color: #ff0000">未查询到该专业要求的选科信息</span>
                    <span v-if="
                      scope.row.courseRequire[0] == 0 &&
                      scope.row.courseRequire[1] == 0 &&
                      scope.row.courseRequire[2] == 0 &&
                      scope.row.courseRequire[3] == 0 &&
                      scope.row.courseRequire[4] == 0 &&
                      scope.row.courseRequire[5] == 0
                    " style="color: #ff0000">不限</span>
                  </template>
                </el-table-column>
              </el-table>
              <div class="submit-btn">
                <el-button class="search-btn" type="text" @click="submitMajorList" size="medium">保存专业选择方案
                </el-button>
              </div>
            </div>
            <!-- report end here -->
          </div>
        </div>
      </div>
    </div>

    <!-- 返回首页button -->
    <div style="padding: 20px 30%" class="button-wrapper">
      <a class="lj-btn lj-btn-third lj-btn-block main-button" @click="jumpPage('/')">返回首页
      </a>
    </div>
  </div>
</template>

<script>
import {
  PersonalInfo,
  XuankeInfo,
  SelectMajorInfo,
  QueryStep,
  queryReport,
  selectableCombo
} from './../../../api/xuanke'
import { subjectadvice } from './../../../api/report'
import majorIndexPage from './../Gaokao/courseRequire.vue'
import $ from 'jquery'
import './../../../Public/utils/new-index/js/jarallax'
import './../../../Public/utils/new-index/js/jquery.totemticker'
import './../../../Public/utils/new-index/js/move-top'
import './../../../Public/utils/new-index/js/easing'
$(function () {
  $('#vertical-ticker').totemticker({
    row_height: '75px',
    next: '#ticker-next',
    previous: '#ticker-previous',
    stop: '#stop',
    start: '#start',
    mousestop: true
  })
})

$(document).ready(function ($) {
  $('.scroll').click(function (event) {
    event.preventDefault()
    $('html,body').animate(
      {
        scrollTop: $(this.hash).offset().top
      },
      1000
    )
  })
})

$(document).ready(function () {
  $().UItoTop({
    easingType: 'easeOutQuart'
  })
})

export default {
  name: 'MoniXuanke',
  components: { majorIndexPage },
  data: function () {
    return {
      subjectRecommandGet: false, // 是否有推荐选科组合
      msg: '',
      recommandSubjects: [],
      recommandScore: [], // 推荐组合和匹配度

      currentStep: 1,
      rules: {
        schoolname: [
          { required: true, message: '请输入学校名称', trigger: 'blur' }
        ],
        username: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        studentidnum: [
          { required: true, message: '请输入学号', trigger: 'blur' }
        ],
        enrollyear: [
          { required: true, message: '请选择入学年份', trigger: 'blur' }
        ]
      },
      account: '',
      postForm: {
        classname: '',
        enrollyear: '',
        gender: '',
        phonenumber: '',
        schoolloccity: '',
        schoolloccounty: '',
        schoollocprovince: '',
        schoolname: '',
        studentidnum: '',
        username: '',
        choose1from2: 'physics_require',
        choose2from4: ['chemistry_require', 'biology_require']
      },

      choose1from2: [
        { name: '物理', choosed: true, label: 'physics_require' },
        { name: '历史', choosed: false, label: 'history_require' }
      ],
      // 四选二科目
      choose2from4: [
        {
          name: '化学+生物',
          selectable: false,
          choosed: false,
          label: ['chemistry_require', 'biology_require']
        },
        {
          name: '化学+地理',
          selectable: false,
          choosed: false,
          label: ['chemistry_require', 'geography_require']
        },
        {
          name: '化学+政治',
          selectable: false,
          choosed: false,
          label: ['chemistry_require', 'politics_require']
        },
        {
          name: '生物+地理',
          selectable: false,
          choosed: false,
          label: ['biology_require', 'geography_require']
        },
        {
          name: '生物+政治',
          selectable: false,
          choosed: false,
          label: ['biology_require', 'politics_require']
        },
        {
          name: '地理+政治',
          selectable: false,
          choosed: false,
          label: ['geography_require', 'politics_require']
        }
      ],
      combolist: [
        { choose1from2: 'physics', choose2from4: [] },
        { choose1from2: 'history', choose2from4: [] }
      ],

      xuankeInfo: {
        feature: '纯理科组合，偏理解思考',
        suitablePerson: [
          '理科的逻辑思维能力大幅度优于文科水平',
          '目标基于 985/211，以及重点本科院校的学生们',
          '意向理工农医类专业，或专业意向不明确的考生',
          '善于独立研究、思考问题的学生',
          '对于自然科学有着浓厚兴趣的学生，目标基于各大高校的科研课题(考研方向'
        ],
        coverRate:
          '专业选择面广。物化生这类组合专业覆盖 96.36%，为 12种选科组合覆盖率最高。',
        coverStatus:
          '理科类专业；工科类专业，计算机类、电子信息、材料等；经管类、医学、教育学等也在选择之列。',
        advantage: [
          '学科之间关联紧密。学科之间关联极大，除了三科之间的关联，还与数学的一些通用的理科思维以及计算能力等都是互相印证、互相促进的。',
          '记忆内容较少，理解的内容较多。物化生三个科目偏重于理解能力和逻辑思维能力 的考察，一般记忆的内容很少。'
        ],
        disadvantage: [
          '学习起来难度大。尤其是物理学科难度较大，想得高分也不容易。',
          '选科人数多且优生多，竞争尤为激烈。物理、化学是理科学霸的首选科目，竞争会异常激烈。'
        ],
        hotOrLimitMajor: {
          hot: '除了传统热门专业如计算机与软件、金融、经济类等专业受欢迎以外，一些新兴专业如大数据、人工智能、生物医药等也越来越热。这些专业物化生组合都能报考。',
          limit:
            '由于法学门类下的政治学类、民族学类、马克思主义理论类、公安学类，要求再选科目中必须有政治，因此该选考科目组合不能报考以上专业类。'
        },
        chooseableMajorDetails: {
          totalNum: 706,
          majorLists: null
        }
      },

      getReport: false,
      selectMajorList: []
    }
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.User.haslogin
    },
    composeName: function () {
      let res =
        this.postForm.choose1from2 == 'physics_require' ? '物理' : '历史'
      res += '+'
      switch (this.postForm.choose2from4[0]) {
        case 'chemistry_require':
          res += '化学'
          break
        case 'biology_require':
          res += '生物'
          break
        case 'politics_require':
          res += '政治'
          break
        case 'geography_require':
          res += '地理'
          break
      }
      res += '+'
      switch (this.postForm.choose2from4[1]) {
        case 'chemistry_require':
          res += '化学'
          break
        case 'biology_require':
          res += '生物'
          break
        case 'politics_require':
          res += '政治'
          break
        case 'geography_require':
          res += '地理'
          break
      }
      return res
    }
  },
  mounted: function () {
    this.account = this.$store.state.User.username

    QueryStep({
      account: this.account
    }).then(res => {
      if (res.rescode == 200) {
        console.log('querystep', res)
        subjectadvice({}).then(res => {
          console.log('subjectadvice', res)
          this.subjectRecommandGet = res.rescode == 200
          if (res.rescode == 200) {
            this.recommandScore = res.data.socre
            // this.recommandSubjects = res.data.names
            this.recommandSubjects = []
            this.recommandSubjects.push(res.data.names[0])
            this.recommandSubjects.push(res.data.names[1])
            for (let ix = 0; ix < this.recommandScore.length; ix++) {
              this.recommandScore[ix] = parseInt(this.recommandScore[ix])
            }
          } else if (res.rescode == 201) {
            this.msg = res.message
          }
        })
        selectableCombo({}).then(res => {
          console.log('selectableCombo', res)
          if (res.rescode == 200) {
            this.combolist = res.data
          }
        })

        if (res.steps == 1) {
          this.currentStep = 1
        } else if (res.steps == 2) {
          this.currentStep = 1
        } else if (res.steps == 3) {
          this.currentStep = 3
          this.getReport = false
          this.postForm.choose1from2 = res.choose1from2
          this.postForm.choose2from4 = res.choose2from4
        } else if (res.steps == 4) {
          this.currentStep = 3
          this.getReport = true
          this.postForm.choose1from2 = res.choose1from2
          this.postForm.choose2from4 = res.choose2from4
          this.selectMajorList = res.majorlist
          this.queryReportPost()
        }

        for (let i = 0; i < this.choose1from2.length; i++) {
          if (this.postForm.choose1from2 == this.choose1from2[i].label) {
            this.choose1from2[i].choosed = true
          }
        }
        for (let i = 0; i < this.choose2from4.length; i++) {
          if (
            this.postForm.choose2from4.indexOf(this.choose2from4[i].label) >= 0
          ) {
            this.choose2from4[i].choosed = true
          }
        }
      }
    })

    this.$store.commit('Xuanke/changeCurrentStep', this.currentStep)
    this.$store.commit('Xuanke/change1from2', this.postForm.choose1from2)
    this.$store.commit('Xuanke/change2from4', this.postForm.choose2from4)
  },
  methods: {
    jumpPage: function (dest) {
      if (this.$route.query.page === 'reportcenter') {
        this.$router.push({ path: 'reportCenter' })
        return
      }
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },

    queryReportPost: function () {
      queryReport({}).then(res => {
        if (res.rescode == 200) {
          // console.log('queryReport', res)
          this.postForm.username = res.data.username
          this.postForm.enrollyear = res.data.enrollyear
          this.postForm.schoolname = res.data.schoolname
          this.postForm.classname = res.data.classname
          this.xuankeInfo = res.data.xuankeInfor
        }
      })
    },

    backToPreStep: function () {
      this.currentStep -= 1
    },
    // 填写个人信息无误后，先保存到vuex中
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          // 提交--发送请求
          PersonalInfo({
            account: this.account,
            classname: this.postForm.classname,
            enrollyear: this.postForm.enrollyear,
            gender: this.postForm.gender,
            phonenumber: this.postForm.phonenumber,
            schoolloccity: this.postForm.schoolloccity,
            schoolloccounty: this.postForm.schoolloccounty,
            schoollocprovince: this.postForm.schoollocprovince,
            schoolname: this.postForm.schoolname,
            studentidnum: this.postForm.studentidnum,
            username: this.postForm.username
          }).then(res => {
            if (res.rescode == 200) {
              this.currentStep += 1
              this.$store.commit('Xuanke/changeCurrentStep', this.currentStep)
              this.$store.commit('Xuanke/changeInfo', [
                this.postForm.classname,
                this.postForm.enrollyear,
                this.postForm.gender,
                this.postForm.schoolloccity,
                this.postForm.schoolloccounty,
                this.postForm.schoollocprovince,
                this.postForm.schoolname,
                this.postForm.studentidnum,
                this.postForm.username
              ])
            } else if (res.rescode == 400) {
              // 填写的个人信息不对，存不进去
            }
          })
        } else {
          return false
        }
      })
    },
    // 重置个人信息
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 选科--点击tag
    selectCourseLabel: function (index, tag) {
      if (tag == '2') {
        if (!this.choose1from2[index].choosed) {
          this.choose1from2[index].choosed = true
          if (index == 0) {
            this.choose1from2[1].choosed = false
          } else {
            this.choose1from2[0].choosed = false
          }
          this.postForm.choose1from2 = this.choose1from2[index].label
        }
      }
      if (tag == '4') {
        let label = this.choose2from4[index].label
        if (this.choose2from4[index].selectable) {
          this.postForm.choose2from4 = label
          for (let i = 0; i < this.choose2from4.length; i++) {
            this.choose2from4[i].choosed = false
          }
          this.choose2from4[index].choosed = true
        }
      }
    },

    // 点击确认选科--包括2选1,4选2
    confirmCourse: function (tag) {
      if (tag == 2) {
        if (this.postForm.choose1from2 != '') {
          this.currentStep += 1
          this.$store.commit('Xuanke/changeCurrentStep', this.currentStep)
          this.$store.commit('Xuanke/change1from2', this.postForm.choose1from2)
          var combo_ids = null
          if (this.postForm.choose1from2 == 'physics_require') {
            combo_ids = this.combolist[0]['choose2from4']
          } else {
            combo_ids = this.combolist[1]['choose2from4']
          }
          for (let i = 0; i < this.choose2from4.length; i++) {
            this.choose2from4[i].selectable = false
            this.choose2from4[i].choosed = false
          }
          for (var i = 0; i < combo_ids.length; i++) {
            this.choose2from4[combo_ids[i]].selectable = true
          }
          for (var i = 0; i < this.choose2from4.length; i++) {
            this.choose2from4[i].choosed = false
          }
          this.choose2from4[combo_ids[0]].choosed = true
          this.postForm.choose2from4 = this.choose2from4[combo_ids[0]].label

        } else {
          this.$message.error('必须选择一科')
        }
      } else if (tag == 4) {
        if (this.postForm.choose2from4.length == 2) {
          this.$confirm('确定选科后无法更改，是否确定', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              XuankeInfo({
                account: this.account,
                choose1from2: this.postForm.choose1from2,
                choose2from4: this.postForm.choose2from4
              }).then(res => {
                if (res.rescode == 200) {
                  this.$message({
                    type: 'success',
                    message: '提交成功!'
                  })
                  this.currentStep += 1
                  this.$store.commit(
                    'Xuanke/changeCurrentStep',
                    this.currentStep
                  )
                  this.$store.commit(
                    'Xuanke/change2from4',
                    this.postForm.choose2from4
                  )
                } else if (res.rescode == 400) {
                  // 填写的个人信息不对，存不进去
                }
              })
            })
            .catch(() => {
            })
        } else {
          this.$message.error('您还没选择四选二科目')
        }
      }
    },

    // 获取报告
    getXuankeReport: function (selectMajorList) {
      this.selectMajorList = selectMajorList
      // 页面跳转
      this.getReport = true
      this.queryReportPost()
    },
    // 重新选择需要的科目
    reSelectSubject: function () {
      this.selectMajorList = []
      this.getReport = false
    },
    // 返回重新选择科目，返回首先科目页面
    reSelectCourse: function () {
      this.currentStep = 1
      this.getReport = false
      this.$store.commit('Xuanke/changeCurrentStep', this.currentStep)
    },
    // 将选择的专业信息保存到数据库
    submitMajorList: function () {
      let majoridlist = []
      this.selectMajorList.forEach(element => {
        majoridlist.push(element.majorId)
      })
      // 存储选科信息
      SelectMajorInfo({
        account: this.account,
        majoridlist: majoridlist
      }).then(res => {
        if (res.rescode == 200) {
          this.$message.success('您已成功保存该专业方案')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('./../../../Public/resources/css/components/table.min.css');

* {
  box-sizing: border-box;
  text-align: center;
}

/deep/ .el-tag {
  cursor: pointer;
}

.steps {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  margin-bottom: 40px;
  width: 80%;
  background: #fff;
  margin: 0 auto;
  // min-height: 500px;
  margin-bottom: 40px;
}

.table-item {
  width: 100%;
  background: #fff;
  margin: 0 auto;
  margin-bottom: 40px;
}

.form-item {
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 0;
  flex-wrap: wrap;
  padding: 3rem 0;
  justify-content: center;
}

.choose-tag {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3rem 0;
  padding: 3rem 0;
}

.condition-tag {
  font-size: 2em;
  font-weight: bold;
  // margin: 1rem 40px;
  margin: 1rem 40px;
  height: 60px;
  padding: 5px 40px;
  line-height: 50px;
}

.major-choose {
  width: 100%;
  height: 100%;

  .major-page {
    position: relative;
    width: 100%;
  }
}

.course-choose {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  padding: 2rem 0;

  .label1 {
    font-size: 1.8em;
    font-weight: bold;
  }

  .recommand-title {
    border-bottom: 0.4rem solid #409eff;
    padding-bottom: 2rem;
    margin-bottom: 2.5rem;

    .strong-span {
      color: #f17c67;
      font-size: 2.8rem;
      padding: 0 5px;
    }

    .recommand-subject {
      padding: 0 0.5rem;
      color: #f17c67;
      font-size: 3rem;
    }
  }
}

.course-choose {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  padding: 2rem 3rem;
  margin-bottom: 3rem;

  .strong-span {
    color: #f17c67;
    font-size: 2.8rem;
    padding: 0 5px;
    // text-decoration: underline;
  }

  .recommand-subject {
    padding: 0 0.5rem;
    color: #f17c67;
    font-size: 3rem;
    // text-decoration: underline;
  }
}

/deep/ .el-form {
  width: 50%;
}

/deep/ .el-form-item__content {
  text-align: left;
}

/deep/ .el-step__head.is-finish {
  color: #f17c67;
  border-color: #f17c67;
}

/deep/ .el-step__title.is-finish {
  color: #f17c67;
}

/deep/ .el-table .cell {
  overflow: auto;
  line-height: normal;
  font-size: 1em;
}

/deep/ .el-step__title {
  cursor: Default;
}

.label1 {
  font-size: 2rem;
  line-height: 1.6em;
  letter-spacing: 0.1em;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;

  .result-span {
    padding: 0 0.2rem;
    font-size: 2rem;
    font-weight: bold;
    color: #f17c67;
  }
}

.recommand-subject-container {
  border-bottom: 0.4rem solid #409eff;
  margin-bottom: 2.5rem;

  .main-parts-wrapper {
    padding-bottom: 1.5rem;
  }

  .recommand-title {
    padding-bottom: 2rem;
    padding-left: 2rem;
    text-align: left;

    .strong-span {
      color: #f17c67;
      font-size: 2.8rem;
      padding: 0 5px;
    }

    .recommand-sub {
      text-align: left;
      padding-left: 2rem;
    }

    .recommand-subject {
      padding: 0 0.5rem;
      color: #f17c67;
      font-size: 3rem;
    }
  }
}



// 最后的报告页面
.select-res {
  width: 100%;

  .title {
    font-size: 2.8rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
    font-family: ZZGFSH;
    padding: 5px 0;
    letter-spacing: 0.3rem;
  }

  .res-body {
    position: relative;
    text-align: left;
    margin: 4rem 0;

    .submit-btn {
      width: 100%;
      display: flex;
      justify-content: right;
    }
  }

  .reselect-btn {
    position: relative;
    right: -60%;
    top: -19px;
    height: 20px;
    width: 40%;
    z-index: 100;
  }
}

.search-btn {
  font-size: 18px;
  color: #f5fbff;
  background-color: #f17c67;
  // margin: 0 20px;
  margin-top: 5%;
  padding: 10px 20px;
  cursor: pointer;
}

.back-btn {
  font-size: 18px;
  color: #f5fbff;
  background-color: #f17c67;
  margin: 0 20px;
  padding: 10px 20px;
  cursor: pointer;
}

.report-page {
  font-size: 14px;
  line-height: 1.6em;
  letter-spacing: 0.1em;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  text-align: left;

  .result-span {
    padding: 0 10px;
    font-weight: bold;
    color: #f17c67;
  }

  .page-sub-title {
    user-select: none;
    font-size: 1.6rem;
    padding: 1rem 1rem 1rem;
    font-weight: 600;
    text-align: left;
    text-indent: 0.4rem;
  }

  .page-title {
    user-select: none;
    font-size: 2rem;
    border-bottom: 1px solid #f17c67;
    padding: 1rem 1rem 1rem;
    font-weight: 700;
    text-align: left;
  }

  .part-describe {
    font-size: 1.4rem;
    padding: 1rem 2rem;
    text-indent: 1rem;
    text-align: left;
  }

  .report-table-div {
    box-sizing: border-box;

    .td-text-left {
      text-align: left;

      div {
        text-align: left;
      }
    }
  }

  .lj-text-middle {
    background-color: #f17c67;
    color: #f5fbff;
  }
}

/deep/ .el-tag--dark {
  background-color: #409eff;
  border-color: #409eff;
  color: #fff;
}

/deep/ .el-tag--plain {
  background-color: #fff;
  border-color: #b3d8ff;
  color: #409eff;
}

@media only screen and (min-width: 480px) {
  .select-res {
    padding: 10px 30px;
  }

  .main {
    padding: 1.6rem 0;
  }

  .table-item {
    width: 80%;
  }

  .report-table-div {
    padding: 0 2rem;
  }

  .steps {
    width: 80%;
  }

  .major-choose {
    .label1 {
      padding-top: 20px;
      padding-left: 40px;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 480px) {
  .major-choose {
    .label1 {
      padding-top: 0;
      padding-bottom: 20px;
      padding-left: 10px;
      text-align: left;
    }
  }

  .course-choose {
    padding: 0;
    padding-bottom: 2rem;
  }
}


.unable-choose {
  cursor: not-allowed;
  color: #8cc5ff;
  background-color: #ecf5ff;
  border-color: #d9ecff;
}
</style>
