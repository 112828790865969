/**
 * 模拟选科发送的数据
 */

import { Service } from "@/utils/Service";

// 查询现在填到了哪儿
export function QueryStep (data) {
  return Service({
    url: '/complement/',
    data: data
  });
}

// 发送个人信息
export function PersonalInfo (data) {
  return Service({
    url: '/personalinfo/',
    data: data
  });
}

// 发送选科信息
export function XuankeInfo (data) {
  return Service({
    url: '/selectcourse/',
    data: data
  });
}

// 存储所有的选择的专业信息
export function SelectMajorInfo (data) {
  return Service({
    url: '/selectmajor/',
    data: data
  });
}


// 选科报告页面
export function queryReport (data) {
  return Service({
    url: '/subjectselection/report/',
    data: data
  });
}
// ## 可选科目
export function selectableCombo (data) {
  return Service({
    url: '/subjectselection/getselectablesubjectcombostudent/',
    data: data
  });
}